<template>
  <div class="card card-custom px-md-10">
    <div class="card-body p-0">
      <div class="row px-8 px-lg-10 py-8">
        <h2>İş Ortağı Ekle</h2>
      </div>
      <!--begin: Wizard-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps pt-8 pt-lg-10 px-8 px-lg-10 mx-4">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <div class="wizard-box">
                  <p>1</p>
                </div>
              </div>
              <div class="wizard-divider d-lg-block d-none"></div>
              <p class="wizard-text d-block d-lg-none">Şirket Bilgileri</p>
            </div>

            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <div class="wizard-box">
                  <p>2</p>
                </div>
              </div>
              <div class="wizard-divider d-lg-block d-none"></div>
              <p class="wizard-text d-block d-lg-none">Şirket Sahibi</p>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <div class="wizard-box">
                  <p>3</p>
                </div>
              </div>
              <p class="wizard-text d-block d-lg-none">Görev Ataması</p>
            </div>
          </div>
          <div class="d-lg-block d-none">
            <div
              class="d-flex px-8 px-lg-10 pb-8 pb-lg-10 pt-2 justify-content-between"
            >
              <p class="wizard-text">Şirket Bilgileri</p>
              <p class="wizard-text">Şirket Sahibi</p>
              <p class="wizard-text">Görev Ataması</p>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-9">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h3 class="mb-10 font-weight-bold text-dark">
                  Şirket Bilgileri
                </h3>
                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Ad Soyad</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="Ad Soyad"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>TC</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="TC"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Şirket Adı</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="Şirket Adı"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Ticari Unvan</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="Ticari Unvan"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Şirket Türü</label>
                      <select
                        name="delivery"
                        class="form-control form-control-lg"
                      >
                        <option value="seciniz" selected required>
                          Seçiniz
                        </option>
                        <option value="overnight">Limited Şirket</option>
                        <option value="express">Anonim Şirket</option>
                        <option value="basic">Kollektif Şirket</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Vergi Türü</label>
                      <select
                        name="delivery"
                        required
                        class="form-control form-control-lg"
                      >
                        <option value="seciniz" selected>Seçiniz</option>
                        <option value="overnight">Gelir Vergisi</option>
                        <option value="express">Kurumlar Vergisi</option>
                        <option value="basic">Damga Vergisi</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Vergi Dairesi</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="Vergi Dairesi"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Vergi Kimlik No</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="Vergi Kimlik No"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Şirket Kurululuş Tarihi</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="Şirket Kuruluş Tarihi"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Hizmet Alanı</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="Hizmet Alanı"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Şirket Telefonu</label>
                  <masked-input
                    class="form-control form-control-lg"
                    v-model="phone"
                    mask="\+\90 (111) 111-1111"
                    placeholder="+90 (5__) ___-____"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>İl</label>
                  <select
                    name="delivery"
                    class="form-control form-control-lg"
                    required
                  >
                    <option value="" selected>İl</option>
                    <option value="overnight">
                      Overnight Delivery (within 48 hours)
                    </option>
                    <option value="express">
                      Express Delivery (within 5 working days)
                    </option>
                    <option value="basic">
                      Basic Delivery (within 5 - 10 working days)
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>İlçe</label>
                  <select name="delivery" class="form-control" required>
                    <option value="" selected>İlçe</option>
                    <option value="overnight">
                      Overnight Delivery (within 48 hours)
                    </option>
                    <option value="express">
                      Express Delivery (within 5 working days)
                    </option>
                    <option value="basic">
                      Basic Delivery (within 5 - 10 working days)
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Adres</label>
                  <Textarea />
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">Şirket Sahibi</h4>
                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>İsim</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="İsim"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Soyisim</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="Soyisim"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>TC</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="TC"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Doğum Tarihi</label>
                      <masked-input
                        v-model="date"
                        class="form-control form-control-lg"
                        mask="11/11/1111"
                        placeholder="GG/AA/YY"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Telefon Numarası</label>
                      <masked-input
                        class="form-control form-control-lg"
                        v-model="phone"
                        mask="\+\90 (111) 111-1111"
                        placeholder="+90 (5__) ___-____"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>İl</label>
                  <select
                    name="delivery"
                    class="form-control form-control-lg"
                    required
                  >
                    <option value="" selected>Yaşadığı İl</option>
                    <option value="overnight">
                      Overnight Delivery (within 48 hours)
                    </option>
                    <option value="express">
                      Express Delivery (within 5 working days)
                    </option>
                    <option value="basic">
                      Basic Delivery (within 5 - 10 working days)
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>İlçe</label>
                  <select name="delivery" class="form-control" required>
                    <option value="" selected>Yaşadığı İlçe</option>
                    <option value="overnight">
                      Overnight Delivery (within 48 hours)
                    </option>
                    <option value="express">
                      Express Delivery (within 5 working days)
                    </option>
                    <option value="basic">
                      Basic Delivery (within 5 - 10 working days)
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Adres</label>
                  <Textarea />
                </div>
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">Görev Ataması</h4>
                <div class="row">
                  <div class="col-md-12 col-12">
                    <div class="form-group">
                      <label>Görev Başlığı</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="Görev Başlığı"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Yapılan Görev Ataması</label>
                      <select name="" class="form-control form-control-lg">
                        <option value="seciniz" selected required>
                          Seçiniz
                        </option>
                        <option value="gorev1">Görev 1</option>
                        <option value="gorev2">Görev 2</option>
                        <option value="gorev3">Görev 3</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Görevi Talep Eden Kişi</label>
                      <select name="" class="form-control form-control-lg">
                        <option value="seciniz" selected required>
                          Seçiniz
                        </option>
                        <option value="kisi1">Kişi 1</option>
                        <option value="kisi2">Kişi 2</option>
                        <option value="kisi3">Kişi 3</option>
                      </select>
                    </div>
                  </div>
                </div>
                <h4 class="mb-10 font-weight-bold text-dark mt-10">
                  Görev Lokasyonu
                </h4>
                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>İl</label>
                      <select
                        name="il"
                        class="form-control form-control-lg"
                        required
                      >
                        <option value="seciniz" selected>Seçiniz</option>
                        <option value="istanbul">İstanbul</option>
                        <option value="sakarya">Sakarya</option>
                        <option value="kocaeli">Kocaeli</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>İlçe</label>
                      <select
                        name="ilce"
                        class="form-control form-control-lg"
                        required
                      >
                        <option value="seciniz" selected>Seçiniz</option>
                        <option value="atasehir">Ataşehir</option>
                        <option value="kadikoy">Kadıköy</option>
                        <option value="uskudar">Üsküdar</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Mahalle</label>
                      <select
                        name="mahalle"
                        class="form-control form-control-lg"
                        required
                      >
                        <option value="seciniz" selected>Seçiniz</option>
                        <option value="kayisdagi">Kayışdağı</option>
                        <option value="kucukbakkalkoy">Küçükbakkalköy</option>
                        <option value="kozyatagi">Kozyatağı</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Sokak</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="Sokak"
                      />
                    </div>
                  </div>
                  <div class="col-md-12 col-12">
                    <div class="form-group">
                      <label>Adres</label>
                      <Textarea />
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    Geri
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    v-on:click="submit"
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Gönder
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-next"
                  >
                    İleri
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";

.wizard-text {
  color: $dveb-primary;
  font-size: 1.38462rem;
  font-family: "Poppins";
  line-height: 1.53846rem;

  @media (max-width: 990px) {
    flex: auto;
    text-align: start;
    margin-left: 1rem;
  }

  &:not(:last-child) {
    flex: 1;
  }
}

.wizard-step:not(:last-child) {
  flex: 1;
  position: relative;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
  margin: 0;
}

.wizard-divider {
  height: 1rem;
  background-color: $dveb-divider;
  flex: 1;

  &::before {
    content: "";
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 70%;
    background-color: $dveb-divider;
    position: absolute;
    left: 3rem;
    top: 0;
    margin-left: -2rem;
    margin-top: 0.32rem;
  }

  &::after {
    content: "";
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 70%;
    background-color: $dveb-divider;
    position: absolute;
    right: 3rem;
    top: 0;
    margin-right: -5.7rem;
    margin-top: 0.32rem;
  }
}

.wizard-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.6875rem;
  height: 3.6875rem;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid $dveb-divider;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  z-index: 2;
}

.wizard-step[data-wizard-state="current"],
.wizard-step[data-wizard-state="done"] {
  .wizard-label .wizard-box {
    background-color: $dveb-info;
    color: #fff;
    border: none;
  }
}

.wizard-step[data-wizard-state="done"] {
  .wizard-divider {
    background-color: $dveb-info;
  }
  .wizard-divider::before {
    content: "";
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 70%;
    background-color: $dveb-info;
    position: absolute;
    left: 3rem;
    top: 0;
    margin-left: -2rem;
    margin-top: 0.32rem;
  }
  .wizard-divider::after {
    content: "";
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 70%;
    background-color: $dveb-info;
    position: absolute;
    right: 3rem;
    top: 0;
    margin-right: -5.7rem;
    margin-top: 0.32rem;
  }
}

.radio-container {
  border: 0.5px solid rgba($dveb-primary, 0.5);
  padding: 3.07692rem;
  border-radius: 10px;

  .radio-group {
    display: flex;
    justify-content: space-between;

    @media (max-width: 990px) {
      flex-direction: column;
      gap: 1.5rem;
    }

    label {
      font-size: 1.53846rem;
      cursor: pointer;

      &::before {
        border-radius: 5px;
        width: 1.84615rem;
        height: 1.84615rem;
        border-color: rgba($dveb-primary, 1);
        border-width: 2px;
        left: -3rem;
      }

      &::after {
        width: 1.84615rem;
        height: 1.84615rem;
        left: -3rem;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E") !important;
      }
    }
  }
}

.form-control {
  border-color: $dveb-primary;
  background-color: #fff;

  &:focus {
    background-color: inherit;
  }
}

.tag-area {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid $dveb-primary;
  border-radius: 4px;
  padding: 0.65rem 1rem;
  gap: 0.5rem;

  span {
    align-items: center;
    border-radius: 0.375rem;
    display: inline-flex;
    justify-content: center;
    padding: 0.15rem 0.25rem;
    color: #fff;

    button {
      padding: 0 0.5rem;
    }
  }

  input {
    outline: none;
    width: 50%;
  }
}
</style>

<script>
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import MaskedInput from "vue-masked-input";

export default {
  name: "is-ortagi-ekle",
  mounted() {
    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  data() {
    return {
      date: "",
      phone2: "",
      phone: "",
      tagInput: "",
      tags: [],
      colors: [
        "#3F98FF80",
        "#10264080",
        "#4033FF80",
        "#2EB6E880",
        "#B3750980",
        "#2E54E880",
      ],
    };
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
    },
    addTag() {
      if (this.tagInput.trim() !== "") {
        const newTag = this.tagInput.trim();
        if (!this.tags.includes(newTag)) {
          this.tags.push(newTag);
        }
        this.tagInput = ""; // Input alanını sıfırla
      }
    },

    removeTab(tag) {
      const index = this.tags.indexOf(tag);
      if (index !== -1) {
        this.tags.splice(index, 1);
      }
    },
    getRandomColor() {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      return this.colors[randomIndex];
    },
  },
  components: {
    MaskedInput,
  },
};
</script>
